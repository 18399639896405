<template>
  <v-textarea
    :value="value"
    :autofocus="autofocus"
    name="field"
    :rules="myRules"
    :label="label"
    :hint="hint"
    :rows="myRows"
    outlined
    dense
    auto-grow
    persistent-hint
    v-bind="$attrs"
    @input="i => $emit('input', i)"
  />
</template>
<script setup>
import { computed } from 'vue'
const props = defineProps({
  label: {
    type: String,
    default: 'Field',
  },
  value: [String, Number],
  autofocus: Boolean,
  required: Boolean,
  rows: {
    type: [String, Number],
    default: 2,
  },
  hint: String,
  rules: {
    type: Array,
    default: () => [],
  },

})
const myRows = computed(() => {
  return Number(props.rows)
})

const myRules = computed(() => {
  return props.required
    ? [
        v => (v && v !== '') || 'Field is required',
        ...props.rules,
      ]
    : [...props.rules]
})
</script>
