<template>
  <v-btn
    :disabled="disabled"
    :loading="myLoading"
    class="white--text"
    color="primary"
    :small="small"
    :icon="icon"
    :title="label"
    @click="$emit('save')"
  >
    <v-icon :left="!icon">
      {{ mdiContentSaveOutline }}
    </v-icon>
    <span slot="loader">
      <v-icon
        light
        class="nv-spin"
      >{{ mdiSyncCircle }}</v-icon>
    </span>
    <template v-if="!icon">
      <slot>{{ label }}</slot>
    </template>
  </v-btn>
</template>

<script setup>
import { computed } from 'vue'
import {
  mdiContentSaveOutline,
  mdiSyncCircle,
} from '@mdi/js'

const props = defineProps({
  small: Boolean,
  icon: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: 'Save',
  },
  label: {
    type: String,
    default: 'Save',
  },
  spin: Boolean,
  loading: Boolean,
})

const myLoading = computed(() => {
  return props.loading === true || props.spin === true
})
</script>
