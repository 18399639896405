<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="true"
    offset-y
    allow-overflow
    max-height="75%"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        dense
        :flat="flat"
        hide-details
        :prepend-inner-icon="$options.mdiMagnify"
        :solo="solo"
        :outlined="outlined"
        clearable
        v-bind="attrs"
        :label="label"
        autocomplete="off"
        v-on="on"
        @input="search"
      />
    </template>

    <v-card>
      <v-card-actions
        class="my-0 py-0"
      >
        <v-spacer />
        <v-btn
          x-small
          text
          @click="menu = false"
        >
          Close <v-icon
            x-small
            right
          >
            {{ $options.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-actions>

      <v-list
        v-if="hits.length === 0"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list
        class="my-0 py-0"
      >
        <template
          v-for="hit in hits"
        >
          <GuestItem
            v-if="hit.type === 'Guest'"
            :key="hit.objectID"
            :item="hit"
            @selected="$emit('selected', { type: 'Guest', id: hit.id })"
          />
          <ReservationItem
            v-else
            :key="hit.objectID"
            :item="hit"
            @selected="$emit('selected', { type: 'Reservation', id: hit.id })"
          />
        </template>
      </v-list>

      <v-card-actions>
        <v-spacer />
        <v-img
          height="18"
          width="126"
          src="https://res.cloudinary.com/hilnmyskv/image/upload/q_auto/v1596652690/Algolia_com_Website_assets/images/shared/algolia_logo/search-by-algolia-light-background.png"
          position="right center"
          contain
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import debounce from 'lodash.debounce'
import GuestItem from '@/components/search-list-item-guest.vue'
import ReservationItem from '@/components/search-list-item-reservation.vue'
import {
  mdiMagnify,
  mdiClose,
} from '@mdi/js'

export default {
  mdiMagnify,
  mdiClose,

  components: {
    GuestItem,
    ReservationItem,
  },
  props: {
    autofocus: Boolean,
    propertyId: String,
    filterGuests: Boolean,
    filterReservations: Boolean,
    flat: Boolean,
    solo: Boolean,
    outlined: Boolean,
  },
  data () {
    return {
      menu: false,
      hits: [],
    }
  },

  computed: {
    index () {
      if (!this.propertyId) return
      return this.$search.initIndex(`${CK_CONFIG.VUE_APP_ALGOLIA_ENV}_${this.propertyId}`)
    },
    label () {
      if (this.filterGuests && this.filterReservations) return 'Search Guests and Reservations'
      if (this.filterGuests) return 'Search Guests'
      return 'Search Reservations'
    },
    facetFilters () {
      if (this.filterGuests && this.filterReservations) return []
      return [
        this.filterGuests ? 'type:Guest' : undefined,
        this.filterReservations ? 'type:PropertyOrder' : undefined,
      ].filter(Boolean)
    },
  },

  created () {
    this.search = debounce(this.search, 500)
  },

  methods: {
    search (val) {
      if (!val) {
        this.hits = []
        return
      }

      const opts = {
        facetFilters: this.facetFilters,
      }
      this.index.search(val, opts)
        .then(({ hits }) => {
          this.hits = hits
        })
    },

  },
}
</script>
