<template>
  <component
    :is="currentLayout"
    :class="layoutClasses"
  >
    <router-view
      :class="pageWrapperClasses"
    />
  </component>
</template>
<script setup>
import { computed } from 'vue'
const props = defineProps({
  layouts: {
    type: Object,
  },
  current: {
    type: String,
  },
  layoutClasses: {
    type: [Object, Array],
    default () {
      return ['layout']
    },
  },
  pageWrapperClasses: {
    type: [Object, Array],
    default () {
      return ['page-wrapper']
    },
  },
})
/**
* Return layout component name for current route
* @return {string}
*/
const currentLayout = computed(() => {
  return props.current ? props.layouts[props.current] : props.layouts.LoadingLayout
})
</script>
