<template>
  <v-navigation-drawer
    v-model="myDrawer"
    fixed
    right
    :width="width"
    class="primary"
  >
    <v-card
      flat
      tile
    >
      <v-toolbar
        dark
        flat
        tile
      >
        <v-toolbar-title>
          <slot name="title" />
        </v-toolbar-title>
        <v-spacer />
        <slot name="toolbar">
          <v-btn
            class="secondary black--text"
            @click="$emit('close')"
          >
            Close
          </v-btn>
        </slot>
      </v-toolbar>
      <v-card-text class="primary">
        <slot />
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script setup>
import { computed } from 'vue'

const emits = defineEmits(['close'])
const props = defineProps({
  width: {
    type: String,
    default: '600px',
  },
  drawer: Boolean,
})
const myDrawer = computed({
  get () {
    return props.drawer
  },
  set (v) {
    if (!v) emits('close')
  },
})
</script>
