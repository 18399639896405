import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Resize } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Resize,
  },
})

export const theme = {
  dark: false,
  default: 'light',
  themes: {
    light: {
      primary: {
        base: '#2F4944',
      },
      secondary: { base: '#b8dad0' },
      accent: { base: '#994D4C' },
      muted: { base: '#ccc' },

      inactive: { base: '#E53935' }, // incomplete
      pending: { base: '#FFEB3B' },
      active: { base: '#00E676' }, // approved
      cancelled: { base: '#E0E0E0' },

      approved: { base: '#2196F3' },
      checkedin: { base: '#FFC107' },
      complete: { base: '#4CAF50' },
    },
    dark: {
      primary: {
        base: '#3183C8',
        lighten1: '#63A2D8',
        lighten2: '#AAD4F5',
        lighten3: '#EFF8FF',
        darken1: '#2368A2',
        darken2: '#1A4971',
        darken3: '#203D54',
      },
      error: {
        base: '#DC3030',
        lighten1: '#E46464',
        lighten2: '#F5AAAA',
        lighten3: '#FCE8E8',
        darken1: '#B82020',
        darken2: '#891B1B',
        darken3: '#611818',
      },
      warning: {
        base: '#F4CA64',
        lighten1: '#FAE29F',
        lighten2: '#FDF3D7',
        lighten3: '#FFFCF4',
        darken1: '#CAA53D',
        darken2: '#8C6D1F',
        darken3: '#5C4813',
      },
      success: {
        base: '#38C172',
        lighten1: '#74D99F',
        lighten2: '#A8EEC1',
        lighten3: '#E3FCEC',
        darken1: '#259D58',
        darken2: '#197741',
        darken3: '#145239',
      },
      info: {
        base: '#3CAEA3',
        lighten1: '#6ED7D3',
        lighten2: '#A8EEEB',
        lighten3: '#E7FFFE',
        darken1: '#2A9187',
        darken2: '#1B655E',
        darken3: '#145239',
      },
    },
  },
}

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme,
})

/**

#151614
#2F4944 (toolbar)
#497C73 (portlet header)
#994D4C
#B8DAD0
#E4E4DF
#E5F1EF (footer)
#F5F5F3

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
  iconfont: 'fa',
  theme: {
    primary: '#1C2541',
    secondary: '#3454d1',
    accent: '#3A7B79',
    error: '#d1345b',
    info: '#8496CE',
    success: '#3454d1',
    warning: '#00FF00',
  },

})

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {
      light: {
        primary: {
          base: '#3183C8',
          lighten1: '#63A2D8',
          lighten2: '#AAD4F5',
          lighten3: '#EFF8FF',
          darken1: '#2368A2',
          darken2: '#1A4971',
          darken3: '#203D54',
        },
        error: {
          base: '#DC3030',
          lighten1: '#E46464',
          lighten2: '#F5AAAA',
          lighten3: '#FCE8E8',
          darken1: '#B82020',
          darken2: '#891B1B',
          darken3: '#611818',
        },
        warning: {
          base: '#F4CA64',
          lighten1: '#FAE29F',
          lighten2: '#FDF3D7',
          lighten3: '#FFFCF4',
          darken1: '#CAA53D',
          darken2: '#8C6D1F',
          darken3: '#5C4813',
        },
        success: {
          base: '#38C172',
          lighten1: '#74D99F',
          lighten2: '#A8EEC1',
          lighten3: '#E3FCEC',
          darken1: '#259D58',
          darken2: '#197741',
          darken3: '#145239',
        },
        info: {
          base: '#3CAEA3',
          lighten1: '#6ED7D3',
          lighten2: '#A8EEEB',
          lighten3: '#E7FFFE',
          darken1: '#2A9187',
          darken2: '#1B655E',
          darken3: '#145239',
        },
      },
    },
  },
})
**/
