<template>
  <LayoutBroker
    :layouts="LAYOUTS"
    :current="($route.meta && $route.meta.layout) || defaultLayout"
    v-bind="$attrs"
  />
</template>

<script setup>
import PropertyLayout from './layouts/property'
import AssociationLayout from './layouts/association'
import SingleLayout from './layouts/single'
import LoadingLayout from './layouts/loading'
import LayoutBroker from './layouts/broker'
import BlankLayout from './layouts/blank'
import UserLayout from './layouts/user'

const LAYOUTS = {
  AssociationLayout,
  LoadingLayout,
  PropertyLayout,
  SingleLayout,
  BlankLayout,
  UserLayout,
}

const defaultLayout = 'BlankLayout'
</script>
<style>
/* https://freedium.cfd/https://jareklipski.medium.com/sticky-table-header-in-vuetify-js-fab39988dc3 */
/* Doesn't work so well
.v-data-table .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table .v-data-table__wrapper table thead tr {
  position: sticky;
  top: 48px
}
*/
</style>
