import { useQuery, useMutation } from '@vue/apollo-composable'
import PeoplePageGQL from '@/gql/people-paging.gql'
import PeopleGQL from '@/gql/person.gql'
import PersonGQL from 'GQL/person.gql'
import PropertyGuestGQL from 'GQL/property-guest.gql'
import PropertyGuestSourcesGQL from 'GQL/property-guest-sources.gql'

export function getBy (input) {
  return useQuery(PeopleGQL, input)
}

export function upsertPerson () {
  return useMutation(PersonGQL)
}

export async function createPropertyGuest (input) {
  const { mutate } = useMutation(PropertyGuestGQL)
  try {
    const { data, errors } = await mutate({ input })
    if (errors || data === null) {
      console.error(errors)
      throw new Error('Unable to add guest')
    }
    return data.propertyGuest
  } catch (e) {
    console.error(e)
    throw e
  }
}

export function guestSources (input) {
  return useQuery(
    PropertyGuestSourcesGQL,
    input,
    {
      fetchPolicy: 'cache-and-network',
    }
  )
}

export function pagePeople (input) {
  return useQuery(
    PeoplePageGQL,
    input,
    {
      fetchPolicy: 'cache-and-network',
    }
  )
}

export function updatePerson (input = {}, proxy) {
  return proxy.$apollo.mutate({
    mutation: PersonGQL,
    variables: { input },
  })
}

export function getPersonFirstPhone (person) {
  return person?.phones?.[0]
}
export function getPersonFirstEmail (person) {
  return person?.emails?.[0]
}
export function getPersonFirstLocation (person) {
  return person?.locations?.[0]
}
