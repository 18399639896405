import startCase from 'lodash.startcase'
import Vue from 'vue'
import { provideApolloClient } from '@vue/apollo-composable'
import App from './app.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/'
import VueApollo from 'vue-apollo'
import { client } from './apollo-client'
import VCalendar from 'v-calendar'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import posthogPlugin from './plugins/posthog' // import the plugin

Vue.use(posthogPlugin) // install the plugin, before new Vue()

const pinia = createPinia()
Vue.use(PiniaVuePlugin)
pinia.use(piniaPluginPersistedstate)

const apolloProvider = new VueApollo({
  defaultClient: client,
})

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
})

Vue.use(VueApollo)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  apolloProvider,
  pinia,
  setup () {
    provideApolloClient(client)
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = `CabinKey :: ${to.meta.title || startCase(to.name)}`
      },
    },
  },
  render: h => h(App),

}).$mount('#app')
