<template>
  <span
    class="headway-widget"
  />
</template>

<script setup>
import { useScriptTag } from '@vueuse/core'
const account = CK_CONFIG.VUE_APP_HEADWAY_ID
if (account) {
  useScriptTag(
    '//cdn.headwayapp.co/widget.js',

    // on script tag loaded.
    (el) => {
      const config = { selector: '.headway-widget', account }
      Headway.init(config) /* eslint-disable-line no-undef */
    },
  )
}
</script>
