<template>
  <v-card
    flat
    class="pt-1"
  >
    <v-row dense>
      <v-col
        cols="12"
        sm="6"
      >
        <TextField
          v-model="firstName"
          label="First Name"
          hint="Enter guests first name"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <TextField
          v-model="lastName"
          label="Last Name"
          hint="Enter guests last name"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <TextField
          v-model="company"
          label="Company"
          hint="Enter a company if any"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <TextField
          v-model="email"
          label="Email"
          hint="Optional: Enter guest email address"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <TextField
          v-model="phone"
          v-mask="'(###) ###-####'"
          label="Phone"
          hint="Optional: Enter guest phone number"
        />
      </v-col>

      <v-col cols="12">
        <TextArea
          v-model="street"
          label="Street"
          rows="1"
          hint="Optional: Enter street - multiple lines allowed"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <TextField
          v-model="city"
          label="City"
          hint="Optional: Enter city"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-autocomplete
          v-model="state"
          label="State"
          :items="$options.stateOptions"
          :item-text="'label'"
          :item-value="'value'"
          hint="Optional: Select State"
          outlined
          dense
        />
      </v-col>

      <v-col cols="6">
        <TextField
          v-model="postal"
          label="ZIP"
          hint="Type in a zip to prefill city, state, and country"
          @input="autoPopulate"
        />
      </v-col>

      <v-col cols="6">
        <TextField
          v-model="country"
          label="Country"
        />
      </v-col>

      <v-col cols="6">
        <GuestSources
          v-model="source"
          :property-id="propertyId"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import stateOptions from '@/utils/state-options'
import TextField from '@/components/text-field.vue'
import { getAddress } from '@/services/geocoder'
import TextArea from '@/components/text-area.vue'
import GuestSources from '@/components/guest-source-select.vue'

export default {
  stateOptions,

  components: {
    TextField,
    TextArea,
    GuestSources,
  },
  props: {
    propertyId: String,
    value: {
      type: Object,
      default: () => ({
        company: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        postal: '',
        country: 'US',
        source: 'CabinKey',
      }),
    },
  },
  data () {
    return {
      input: { ...this.value },
    }
  },
  computed: {
    ...[
      'company',
      'firstName',
      'lastName',
      'email',
      'phone',
      'street',
      'city',
      'postal',
      'state',
      'country',
      'source',
    ].reduce((memo, key) => {
      memo[key] = {
        get () {
          return this.input[key]
        },
        set (val) {
          this.input[key] = val
          this.$emit('input', this.input)
        },
      }
      return memo
    }, {}),
  },
  methods: {
    async autoPopulate (postal = '') {
      let data
      try {
        data = await getAddress(postal)
      } catch (error) {
        console.error('Failed to auto-populate address:', error)
        return
      }
      if (!data) return
      if (!data.standard) return
      if (data.standard.prov) {
        this.input.state = data.standard.prov
      }

      if (data.standard.city) {
        this.input.city = data.standard.city
      }

      if (data.country) {
        this.input.country = data.country
      }
    },
  },
}
</script>
