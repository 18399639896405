<template>
  <v-snackbar
    v-model="show"
    bottom
    :color="type"
    :timeout="timeout"
  >
    <strong>{{ title }}</strong><br>
    {{ message }}
  </v-snackbar>
</template>

<script>
import { EventBus } from '@/utils/bus'

const DEFAULT_TIMEOUT = 4000

export default {
  data () {
    return {
      show: false,
      title: '',
      message: '',
      type: 'error',
      timeout: 3000,
    }
  },
  mounted () {
    EventBus.on('show-snack', ({ title = '', message, type = 'error', opts = {} }) => {
      this.title = title
      // When it gets here, message should just be a string
      this.message = typeof message === 'string' ? message : ''
      this.type = type
      this.show = true
      this.timeout = opts.timeout || DEFAULT_TIMEOUT
    })
  },
  methods: {
    close () {
      this.show = false
    },
  },
}
</script>
