<template>
  <v-list-item
    @click="$emit('selected')"
  >
    <v-list-item-action>
      <v-icon>{{ mdiHome }}</v-icon>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        #{{ item.orderNum }}
        {{ fullName }}
      </v-list-item-title>
      <v-list-item-subtitle>{{ stays }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script setup>
import {
  mdiHome,
} from '@mdi/js'
import { computed } from 'vue'

const props = defineProps({
  item: Object,
})

const fullName = computed(() => {
  const p = props.item.people[0]
  return p ? p.fullName : ''
})

const stays = computed(() => {
  return props.item.stays.map(s => `${s.unit.name} (${s.arrival} - ${s.departure})`).join(', ')
})

</script>
