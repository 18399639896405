<template>
  <v-app>
    <LeftNavDrawer
      v-if="links"
      :drawer="drawer"
      :links="links"
      @update:drawer="val => drawer = val"
    />
    <Snackbar />
    <v-app-bar
      color="#2F4944"
      dark
      fixed
      app
      dense
      clipped-left
      class="d-print-none"
    >
      <v-app-bar-nav-icon
        v-if="links"
        @click.stop="drawer = !drawer"
      />
      <v-app-bar-title
        v-if="title"
      >
        {{ title }}
      </v-app-bar-title>
      <slot name="header-quick-action" />
      <v-spacer />
      <HeadwayApp />
      <AppBarSearch
        v-if="isPropertyUrl"
        :property-id="id"
      />

      <v-btn
        v-if="isAdmin"
        :to="{ name: 'MyApe' }"
        small
        icon
        title="Impersonate"
        class="ml-2"
      >
        <v-icon color="white">
          {{ mdiIncognito }}
        </v-icon>
      </v-btn>

      <AccountMenu
        v-if="myProfile"
        :properties="myProfile.properties"
        :associations="myProfile.associations"
        :favorite-property-ids="favoritePropertyIds"
        @select-property="selectProperty"
        @select-association="selectAssociation"
        @logout="onLogout"
      />
    </v-app-bar>
    <v-main style="background-color: #EEF5F9;">
      <v-container
        fluid
        class="align-start"
        :pa-0="smAndDown"
      >
        <v-row
          dense
          class="justify-center align-start "
        >
          <v-col cols="12">
            <ReloadPrompt />
          </v-col>
          <v-col
            cols="12"
            class=""
          >
            <slot>
              <v-row>
                <v-col class="text-center">
                  <v-icon
                    class="nv-spin"
                    size="200"
                  >
                    {{ mdiCog }}
                  </v-icon>
                </v-col>
              </v-row>
            </slot>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <MyFooter
      class="d-print-none"
    />
  </v-app>
</template>

<script setup>
import { ref, computed, inject, getCurrentInstance } from 'vue'
import HeadwayApp from '@/components/headway-app.vue'
import AccountMenu from '@/components/account-menu.vue'
import AppBarSearch from '@/components/app-bar-search.vue'
import Snackbar from '@/components/snackbar.vue'
import MyFooter from '@/components/ck-footer.vue'
import LeftNavDrawer from '@/components/left-nav-drawer.vue'
import ReloadPrompt from '@/components/reload-prompt.vue'
import { useUserPrefs } from '@/store/user-prefs'
import { useRoute, useRouter } from '@/composable/router'
import { smAndDown } from '@/composable/breakpoints'
import {
  mdiIncognito,
  mdiCog,
} from '@mdi/js'

defineProps({
  links: Array,
  source: String,
  title: String,
  id: String,
})

const PROPERTY_URL_REGEX = /^\/property\/*/i
const myProfile = inject('myProfile', {})
const isAdmin = inject('isAdmin', false)
const vm = getCurrentInstance()
const userPrefs = useUserPrefs()
const router = useRouter(vm.proxy)
const route = useRoute(vm.proxy)
const drawer = ref(null)

const isPropertyUrl = computed(() => {
  return PROPERTY_URL_REGEX.test(route.fullPath)
})

const favoritePropertyIds = computed(() => {
  return userPrefs?.favoritePropertyIds || []
})

function selectProperty (property) {
  router.push({ path: `/property/${property.id}` })
}
function selectAssociation (association) {
  router.push({ path: `/association/${association.id}` })
}
function onLogout () {
  router.push({ path: '/auth/logout' })
}

</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

@media print {
  * {
    font-size: .8rem !important;
  }
}

ul.list-unstyled {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.table-heading {
  background-color: #b8dad04a;
}

.keep-spaces {
  white-space: pre-wrap;
}

.active-tab {
  background-color: #ddd;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% {  transform: rotate(359deg); }
}
.nv-spin {
  animation: spin 2s linear infinite;
}
.nv-flip-h {
  transform: scaleX(-1);
}

.unit-card .headline,
.addon-card .headline,
.addon-card .text-h5,
.unit-card .text-h5 {
  font-weight: bold;
  text-shadow: #FFF 0 0 10px;
  background-color: rgba(0, 0, 0, .5);
}

.addon-card .card-img-overlay .card-title,
.unit-card .card-img-overlay .card-title {
  padding: 10px;
  background-color: rgba(0, 0, 0, .5);
}

.addon-card .card-img-overlay .card-title:hover,
.unit-card .card-img-overlay .card-title:hover {
  background-color: rgba(0, 0, 0, 1);
}

.handle:hover {
  cursor: move !important;
  cursor: -webkit-grabbing;
}

.image-card .headline {
  font-weight: bold;
  text-shadow: #FFF 0 0 10px;
  background-color: rgba(0, 0, 0, .5);
}

.image-card .card-img-overlay .card-title:hover {
  background-color: rgba(0, 0, 0, 1);
}
.addon-rate-row .duration,
.unit-rate-row .duration {
  white-space: nowrap;
}

.bed-size-row .nv-pointer:hover {
  background-color: #ddd;
}

.ck-footer,
.ck-footer a {
  color: #151614 !important;
}

/* Enable title to show on disabled buttons */
/* https://stackoverflow.com/a/22867505/179335 */
.v-btn.v-btn--disabled {
  pointer-events: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .03);
}

/* Only display Hubspot chat on the dashboard */
body:not(.property-dashboard) #hubspot-messages-iframe-container {
  display: none !important;
}
</style>
