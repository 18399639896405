<template>
  <MyProfile>
    <Toggles>
      <CkApp
        title="My Profile"
        :links="links"
      >
        <v-container fluid>
          <v-row>
            <v-col>
              <slot />
            </v-col>
          </v-row>
        </v-container>
      </CkApp>
    </Toggles>
  </MyProfile>
</template>

<script setup>
import { computed } from 'vue'
import CkApp from './ck-app.vue'
import Toggles from '@/providers/toggles.vue'
import MyProfile from '@/providers/my-profile.vue'
import {
  mdiAccount,
  mdiDomain,
  mdiCogs,
} from '@mdi/js'

const links = computed(() => {
  const menu = [{
    icon: mdiAccount,
    to: { name: 'MyProfile' },
    name: 'Me',
    isActive: true,
  }, {
    icon: mdiDomain,
    to: { name: 'MyProperties' },
    name: 'Properties',
  }, {
    icon: mdiCogs,
    to: { name: 'MySettings' },
    name: 'My Settings',
  }]

  return menu
})
</script>
