<template>
  <v-list-item
    @click="$emit('selected')"
  >
    <v-list-item-action>
      <v-icon>{{ mdiAccount }}</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title> {{ item.fullName }} </v-list-item-title>
      <v-list-item-subtitle v-if="phones.length || locations.length">
        {{ phones }} :: {{ locations }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script setup>
import {
  mdiAccount,
} from '@mdi/js'
import { computed } from 'vue'

const props = defineProps({
  item: Object,
})

const phones = computed(() => {
  const phones = props.item.phones.map(ph => ph.numberLabel)
  return phones.slice(0, 2).join(', ')
})

const locations = computed(() => {
  const locations = props.item.locations.map(loc => `${loc.city}, ${loc.state}`)
  return locations.slice(0, 2).join(' / ')
})

</script>
