import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

const dsn = CK_CONFIG.VUE_APP_SENTRY_DSN
const environment = CK_CONFIG.VUE_APP_SENTRY_ENV || 'development'
const release = CK_CONFIG.VUE_APP_COMMIT

if (dsn) {
  Sentry.init({
    Vue,
    dsn,
    environment,
    release,
    integrations: [new Integrations.BrowserTracing()],
    // Disable Sample Tracing for now.
    // tracesSampleRate: 1.0,
    // tracingOptions: {
    //   trackComponents: true,
    // },
  })
}
