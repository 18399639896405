import { computed, reactive } from 'vue'

/**
* @returns Router
*/
export function useRouter (proxy) {
  return proxy.$router
}

/**
* @returns Route
*/
export function useRoute (proxy) {
  const currentRoute = computed(() => proxy.$route)

  const protoRoute = Object.keys(currentRoute.value).reduce(
    (acc, key) => {
      acc[key] = computed(() => currentRoute.value[key])
      return acc
    },
    {}
  )

  return reactive(protoRoute)
}
