const replaceRegExp = /[^\w]/gi

const authCode = CK_CONFIG.VUE_APP_GEOCODER_AUTH_CODE
const hostname = 'https://geocoder.ca'

function getCountry (country = '') {
  return country.toLowerCase() === 'canada' ? 'CA' : 'US'
}

export async function getAddress (postal) {
  if (postal.length < 5) return Promise.resolve()
  const value = postal.replace(replaceRegExp, '')

  const query = new URLSearchParams()
  query.set('postal', value)
  query.set('showcountry', '1')
  query.set('json', '1')

  if (authCode) {
    query.set('auth', authCode)
  }

  const url = `${hostname}/?${query.toString()}`
  try {
    const response = await fetch(url)
    const json = await response.json()
    return {
      ...json,
      country: getCountry(json.country),
    }
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.error(e) // eslint-disable-line no-console
    }
    throw e
  }
}
