<template>
  <Search
    :property-id="propertyId"
    filter-guests
    filterReservations
    solo
    flat
    @selected="selected"
  />
</template>

<script setup>
import { getCurrentInstance } from 'vue'
import { useRouter } from '@/composable/router'
import Search from '@/components/search.vue'

const vm = getCurrentInstance()
const router = useRouter(vm.proxy)

defineProps({
  propertyId: String,

})
function selected ({ type, id }) {
  if (type === 'Guest') {
    return router.push({ name: 'Guest', params: { guestId: id } })
  }
  if (type === 'Reservation') {
    return router.push({ name: 'Reservation', params: { reservationId: id } })
  }
}
</script>
