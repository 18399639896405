<template>
  <v-navigation-drawer
    v-if="links"
    v-model="myDrawer"
    fixed
    app
    clipped
    :mini-variant="!userPrefs.navDrawerExpanded"
    width="200"
    class="d-print-none"
  >
    <v-list dense>
      <SidebarItem
        v-for="link in links"
        :key="link.path"
        :link="link"
      />
    </v-list>
    <template #append>
      <v-list dense>
        <v-list-item
          v-if="userPrefs.navDrawerExpanded"
          dense
          @click="() => setNavDrawerExpanded(false)"
        >
          <v-list-item-action>
            <v-icon>{{ mdiChevronDoubleLeft }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Collapse Toolbar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-else
          title="Expand ToolBar"
          dense
          @click="() => setNavDrawerExpanded(true)"
        >
          <v-list-item-action>
            <v-icon>{{ mdiChevronDoubleRight }}</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { computed } from 'vue'
import SidebarItem from '@/components/sidebar-item.vue'
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
} from '@mdi/js'
import { useUserPrefs } from '@/store/user-prefs'
const userPrefs = useUserPrefs()
const emits = defineEmits(['update:drawer'])
const props = defineProps({
  links: Array,
  id: String,
  drawer: Boolean,
})
const myDrawer = computed({
  get () {
    return props.drawer
  },
  set (val) {
    emits('update:drawer', val)
  },
})

function setNavDrawerExpanded (val) {
  userPrefs.navDrawerExpanded = val
}
</script>
