// import get from 'lodash.get'
// import { EventBus } from './bus'

export default function catchGqlError (err) {
  // const type = 'error'
  // const title = 'Ooops...'
  // const message = get(err, 'graphQLErrors[0].message', 'Sorry, operation failed')
  if (process.env.NODE_ENV === 'development') {
    console.error(err) // eslint-disable-line no-console
  }
  // EventBus.$emit('show-snack', { title, message, type })
}
