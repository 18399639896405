<template>
  <v-footer
    color="#E5F1EF"
    class="text-caption pa-1 ck-footer"
    app
  >
    <div><a :href="url">{{ name }}®</a> 2019-{{ theYear }} </div>
    <v-spacer />
    <div>
      Made with <v-icon
        small
        color="red"
      >
        {{ mdiHeart }}
      </v-icon> in Minnesota
    </div>
  </v-footer>
</template>

<script setup>
import {
  mdiHeart,
} from '@mdi/js'

const url = CK_CONFIG.VUE_APP_WWW_URL
const name = CK_CONFIG.VUE_APP_SITE_NAME
const theYear = (new Date()).getUTCFullYear()
</script>
