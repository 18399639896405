<template>
  <v-combobox
    v-model="internalVal"
    label="Select Source"
    :items="sources"
    :loading="loading"
    item-value="name"
    item-text="name"
    v-bind="$attrs"
    hint="How did guest arrive? Select existing or type a new source"
    persistent-hint
    clearable
    outlined
    dense
  />
</template>

<script setup>
import { ref, watch, computed } from 'vue'

import { guestSources } from '@/services/person'

const emits = defineEmits(['input'])
const props = defineProps({
  value: String,
  propertyId: String,
})

const sources = ref([])

const internalVal = computed({
  get () {
    return props.value
  },
  set (val) {
    // If they type in their answer, then val is a string, if they select, then object
    const input = val && val.name ? val.name : val
    emits('input', input)
  },
})

const { result, loading } = guestSources({ input: { propertyId: props.propertyId } })

watch(result, val => {
  if (!val) return
  sources.value = val.propertyGuestSources
})
</script>
