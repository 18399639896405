<template>
  <v-app
    style="background-color: #E7F1EF"
  >
    <Snackbar />
    <v-main>
      <v-img
        :src="src"
        gradient="rgba(255, 255, 255, 0) 85%, #E7F1EF"
        height="90vh"
      >
        <v-container
          fill-height
          fluid
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <slot />
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-main>
    <CkFooter />
  </v-app>
</template>

<script setup>
import { computed } from 'vue'
import CkFooter from '@/components/ck-footer.vue'
import Snackbar from '@/components/snackbar.vue'
const imgs = [
  'chickadee-4135770_1920.jpg',
  'chipmunk-218740.jpg',
  'moose-70254_1920.jpg',
  'loon-1596827_1920.jpg',
  'bear-cub.jpeg',
]

const src = computed(() => {
  const imgixUrl = CK_CONFIG.VUE_APP_IMGIX_URL
  const img = imgs[Math.floor(Math.random() * imgs.length)]
  return `${imgixUrl}/images/assets/${img}?auto=compress&w=800&h=600`
})
</script>
