<template>
  <v-card>
    <v-card-text>
      <v-tabs
        v-model="tab"
        active-class="active-tab"
        fixed-tabs
      >
        <v-tabs-slider />
        <v-tab> Search Existing Guests </v-tab>
        <v-tab> Create New Guest </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card
            flat
            class="pt-2"
          >
            <Search
              :property-id="propertyId"
              filter-guests
              autofocus
              outlined
              @selected="addReservation"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <NewGuestForm
            :key="input.id"
            v-model="input"
            :property-id="propertyId"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <ButtonCancel @cancel="$emit('close')">
        Close
      </ButtonCancel>
      <v-spacer />
      <ButtonSave
        v-if="tab === 1"
        :disabled="!isValid"
        @save="addGuestThenReservation"
      >
        Create Guest
      </ButtonSave>
    </v-card-actions>
  </v-card>
</template>

<script>
import stateOptions from '@/utils/state-options'
import Search from '@/components/search.vue'
import catchGqlError from '@/utils/catch-gql-error'
import PropertyOrderGQL from 'GQL/property-order.gql'
import ButtonSave from '@/components/button-save.vue'
import ButtonCancel from '@/components/button-cancel.vue'
import { required, cityValidations, phoneValidations, emailValidations } from '@/utils/forms'
import PropertyGuestGQL from 'GQL/property-guest.gql'
import NewGuestForm from '@/components/new-guest-form.vue'

const DEFAULT_INPUT = {
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  state: '',
  postal: '',
  country: 'US',
  source: '',
}

export default {
  components: {
    NewGuestForm,
    Search,
    ButtonCancel,
    ButtonSave,
  },
  props: {
    propertyId: String,
    guests: Array,
  },
  data () {
    return {
      tab: null,
      loading: false,
      personId: null,
      input: {
        ...DEFAULT_INPUT,
        id: Date.now(),
      },
    }
  },
  computed: {
    isValid () {
      if (this.loading) return false
      if (this.input.company) return true
      if (this.input.firstName && this.input.lastName) return true
      return false
    },
    phoneRules () {
      return phoneValidations
    },
    nameRules () {
      return [
        required('Field Required'),
      ]
    },
    streetRules () {
      return [
        required('Street Required'),
      ]
    },
    emailRules () {
      return emailValidations
    },
    cityRules () {
      return cityValidations
    },
    stateOptions () {
      return stateOptions
    },

  },
  watch: {
  },
  methods: {
    getLocation () {
      const { street, city, state, postal, country } = this.input
      if (!street) return
      if (!city) return
      if (!state) return
      if (!postal) return
      return {
        street,
        city,
        state,
        postal,
        country,
      }
    },
    getPhone () {
      const { phone: number } = this.input
      if (!number) return
      return {
        number,
        type: 'HOME',
      }
    },
    getEmail () {
      const { email } = this.input
      if (!email) return
      return {
        email,
      }
    },
    getPerson () {
      const location = this.getLocation()
      const phone = this.getPhone()
      const email = this.getEmail()
      return {
        company: this.input.company,
        firstName: this.input.firstName,
        lastName: this.input.lastName,
        phone,
        email,
        location,
        source: this.input.source,
      }
    },

    async addGuestThenReservation () {
      this.loading = true
      const { propertyId } = this
      const person = this.getPerson()

      const { data, error } = await this.$apollo.mutate({
        mutation: PropertyGuestGQL,
        variables: { input: { person, propertyId } },
      })

      if (error) {
        catchGqlError(error)
      }

      const { propertyGuest: { id } } = data
      return this.addReservation({ id })
    },

    addReservation ({ id: personId } = {}) {
      const person = personId ? undefined : this.getPerson()
      this.loading = true
      const { propertyId } = this

      this.$apollo.mutate({
        mutation: PropertyOrderGQL,
        variables: { input: { person, personId, propertyId, status: 'ACTIVE', source: 'DASHBOARD' } },
      })
        .then(({ data }) => {
          // Close any dialog that might be open before navigating away
          this.input = { ...DEFAULT_INPUT, id: Date.now() }
          this.$emit('close')
          const { propertyOrder: { id } } = data
          this.$router.push({ path: `/property/${propertyId}/reservation/${id}` })
        })
        .catch(catchGqlError.bind(this))
        .finally(() => { this.loading = false })
    },
  },
}
</script>
