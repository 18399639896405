import Vue from 'vue'
import algoliasearch from 'algoliasearch'

const client = algoliasearch(
  CK_CONFIG.VUE_APP_ALGOLIA_APP_ID,
  CK_CONFIG.VUE_APP_ALGOLIA_SEARCH_KEY,
)

Vue.use({
  install (Vue) {
    Vue.prototype.$search = client
  },
})
