import { posthog } from '@/plugins/posthog'

export let isFeatureEnabled = () => {
  console.warn('using dummy isFeatureEnabled') /* eslint-disable-line no-console */
  return false
}

posthog.onFeatureFlags(function () {
  // feature flags should be available at this point
  isFeatureEnabled = posthog.isFeatureEnabled.bind(posthog)
})
