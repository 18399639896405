<script setup>
import { provide } from 'vue'
import { isFeatureEnabled } from '@/services/toggle'

provide('isFeatureEnabled', isFeatureEnabled)
</script>

<script>
// Render doesn't work script setup
export default {
  render () {
    // Renderless component, otherwise it messes with the layout of the page
    // https://adamwathan.me/renderless-components-in-vuejs/
    return this.$scopedSlots.default({})
  },
}
</script>
