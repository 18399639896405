<template>
  <div
    v-if="!hidden"
    :title="link.description || link.name"
  >
    <v-list-group
      v-if="hasSubItems"
      :prepend-icon="icon"
      :append-icon="$options.mdiMenuDown"
      :group="link.group"
      :sub-group="subGroup"
    >
      <template #activator>
        <v-list-item-title :title="link.name">
          {{ link.name }}
        </v-list-item-title>
      </template>

      <SidebarItem
        v-for="(subitem, idx) in link.items"
        :key="idx"
        :link="subitem"
        sub-group
      />
    </v-list-group>

    <v-list-item
      v-else
      :to="url"
      dense
      color="blue"
    >
      <v-list-item-action v-show="icon">
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ link.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import {
  mdiMenuDown,
} from '@mdi/js'
export default {
  mdiMenuDown,

  name: 'SidebarItem',
  props: {
    subGroup: Boolean,
    link: {
      type: Object,
      default: () => {
        return { name: '', to: '', icon: '', isActive: false }
      },
    },
  },
  computed: {
    hasSubItems () {
      return !!(this.link.items && this.link.items.length > 0)
    },
    url () {
      return this.link.to
    },
    icon () {
      return this.link.icon
    },
    hidden () {
      return this.link.hidden
    },
  },
}
</script>
