import { defineStore } from 'pinia'

export const useUserPrefs = defineStore('userPrefs', {
  state: () => {
    return {
      navDrawerExpanded: true,
      favoritePropertyIds: [],

      showInactiveUnits: false,
      showInactiveAddons: false,
      showInactiveSaleItems: false,

      dashboardCalendarShowInactive: false,
      dashboardCalendarShowUnits: true,
      dashboardCalendarShowAddons: true,
      dashboardCalendarUnitTypeFilter: [],
    }
  },
  getters: {
    dashboardCalendarHasFilters () {
      // Not showing units or addons, then filtered
      if (!this.dashboardCalendarShowUnits) return true
      if (!this.dashboardCalendarShowAddons) return true

      // While we transition to an array, we need to check for NULL
      if (this.dashboardCalendarUnitTypeFilter === null) return false
      if (typeof this.dashboardCalendarUnitTypeFilter === 'string') return true

      // types of units to filter on
      if (this?.dashboardCalendarUnitTypeFilter?.length) return true
      return false
    },
  },
  actions: {
    toggleFavoriteProperty (val) {
      const arr = this.favoritePropertyIds
      this.favoritePropertyIds = arr.includes(val) ? arr.filter(i => i !== val) : [...arr, val]
    },
  },
  persist: true,
})
