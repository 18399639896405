export default {
  name: 'useRegisterSW',
  data () {
    return {
      updateSW: undefined,
      offlineReady: false,
      needRefresh: false,
    }
  },
  async mounted () {
    try {
      // eslint-disable-next-line
      const { registerSW } = await import('virtual:pwa-register')
      const vm = this
      this.updateSW = registerSW({
        immediate: true,
        onOfflineReady () {
          vm.offlineReady = true
          vm.onOfflineReadyFn()
        },
        onNeedRefresh () {
          vm.needRefresh = true
          vm.onNeedRefreshFn()
        },
        onRegistered (swRegistration) {
          // Set it to update itself
          swRegistration && setInterval(() => {
            swRegistration.update()
          }, 3600000) // Every hour
        },
        onRegisterError (e) {
          vm.handleSWRegisterError(e)
        },
      })
    } catch (e) {
      console.error(e)
      console.log('PWA disabled.') // eslint-disable-line
    }
  },
  methods: {
    async closePromptUpdateSW () {
      this.offlineReady = false
      this.needRefresh = false
    },
    onOfflineReadyFn () {
      console.log('onOfflineReady') // eslint-disable-line
    },
    onNeedRefreshFn () {
      console.log('onNeedRefresh') // eslint-disable-line
    },
    updateServiceWorker () {
      this.updateSW && this.updateSW(true)
    },
    handleSWManualUpdates (swRegistration) {},
    handleSWRegisterError (error) {
      console.error(error)
    },
  },
}
