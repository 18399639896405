/**
 * Create getters and setters for computed form values
 * @param {string} origin - Value on `this` that will be used as a base
 * @param {string[]} keys - Array of values on this.origin to map to this.input
 */
export function mapInput (origin, keys = []) {
  return keys.reduce((memo, key) => {
    memo[key] = {
      get () {
        if (!this[origin]) return
        return this[origin][key]
      },
      set (val) {
        this.input[key] = val
      },
    }
    return memo
  }, {})
}

const PHONE_REG_EXP = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
const EMAIL_REG_EXP = /[\w\.-]+\+?[\w\.-]*@.+\.\w{2,}/ /* eslint-disable-line  */

export function regExp (r, message = 'Invalid entry') {
  return v => r.test(v) || message
}

export function required (message = 'Field Required') {
  return v => (v !== '' && v !== undefined && v !== null) || message
}

export function minLength (len, message = 'Enter valid city name') {
  return v => v.length > len || message
}

/**
 * @param {number} value
 * @param {string} message
 *
 * @returns {Function}
 */
export function currencyMin (value = 0, message = 'Value must be greater than 0') {
  return /** @param {number} v */ v => {
    return v > value || message
  }
}

export function exactLength (len, message = 'Incorrect length') {
  return v => v.length === len || message
}

export function urlValidation (message = 'Use a valid URL') {
  return function (v) {
    if (v === undefined) return true
    try {
      new URL(v) /* eslint-disable-line  no-new */
    } catch (e) {
      return message
    }
    return true
  }
}

export const emailValidations = [
  regExp(EMAIL_REG_EXP, 'Use a valid Email'),
]

export const phoneValidations = [
  required('Phone Required'),
  exactLength(14, 'Please enter 10 digit number'),
  regExp(PHONE_REG_EXP, 'Use a valid Phone'),
]

export const cityValidations = [
  required('City Required'),
  minLength(1, 'Enter valid City name'),
]
