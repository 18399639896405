/* eslint-disable camelcase */
import PH from 'posthog-js'

const KEY = CK_CONFIG.VUE_APP_POSTHOG_ID
const api_host = CK_CONFIG.VUE_APP_POSTHOG_HOST || '/ingest'

export const posthog = PH.init(KEY, {
  api_host,
  bootstrap: {
    featureFlags: {
      'client-show-housekeeping-nav': true,
    },
  },
}) /* eslint-disable-line */

export default {
  install (Vue) {
    Vue.prototype.$posthog = posthog
  },
}
