<template>
  <MyProfile>
    <Toggles>
      <CkApp
        :id="propertyId"
        :links="links"
      >
        <template #header-quick-action>
          <v-btn
            v-show="mdAndUp"
            dark
            right
            small
            @click="drawer = true"
          >
            <v-icon left>
              {{ mdiPlus }}
            </v-icon>
            <span>Add Reservation</span>
          </v-btn>
        </template>
        <v-row dense>
          <v-col
            cols="12"
            xclass="pa-0 ma-0"
          >
            <CkBreadcrumbs
              :property-name="propertyStore.currentProperty?.name"
            />
          </v-col>
        </v-row>
        <slot />
        <RightDrawer
          :drawer="drawer"
          @close="closeDrawer"
        >
          <template #title>
            New Reservation
          </template>
          <ReservationForm
            :key="key"
            :property-id="propertyId"
            @close="closeDrawer"
          />
        </RightDrawer>
      </CkApp>
    </Toggles>
  </MyProfile>
</template>

<script setup>
import { ref, computed } from 'vue'
import CkApp from './ck-app.vue'
import Toggles from '@/providers/toggles.vue'
import MyProfile from '@/providers/my-profile.vue'
import RightDrawer from '@/components/ck-right-drawer.vue'
import ReservationForm from '@/components/reservation-form.vue'
import {
  mdiCalculator,
  mdiCancel,
  mdiChartBar,
  mdiCheckbook,
  mdiCogs,
  mdiContacts,
  mdiCurrencyUsd,
  mdiCart,
  mdiBroom,
  mdiDogSide,
  mdiHome,
  mdiPlus,
  mdiPuzzle,
  mdiReceiptText,
  mdiRuler,
  mdiSpeedometer,
  mdiStorefrontOutline,
} from '@mdi/js'
import { isFeatureEnabled } from '@/services/toggle'
import { clientShowHousekeepingNav } from '@/toggles'
import { useProperty } from '@/store/property'
import CkBreadcrumbs from '@/components/ck-breadcrumbs.vue'
import { mdAndUp } from '@/composable/breakpoints'

const propertyStore = useProperty()

const key = ref(Date.now())
const drawer = ref(false)

const propertyId = computed(() => {
  return propertyStore.currentProperty?.id
})

const links = computed(() => {
  // Get warnings when this tries to render without a propertyId
  if (!propertyStore?.currentProperty?.id) return []

  const params = { propertyId: propertyStore?.currentProperty?.id }

  const newRateVersion = propertyStore?.currentProperty?.rateVersion === 2

  const oldRateNavItems = [{
    icon: mdiCheckbook,
    name: 'Rates',
    group: '/rates/',
    items: [newRateVersion
      ? {
          icon: mdiCalculator,
          to: { name: 'RatePlansAssignments', params },
          name: 'Rate Plans',
        }
      : {
          icon: mdiHome,
          to: { name: 'RatesUnits', params },
          name: 'Unit Rates',
        }, newRateVersion
      ? {
          icon: mdiRuler,
          to: { name: 'StayRulesAssignments', params },
          name: 'Stay Rules',
        }
      : null, {
      icon: mdiPuzzle,
      to: { name: 'RatesAddons', params },
      name: 'Addon Rates',
    }, newRateVersion
      ? null
      : {
          icon: mdiDogSide,
          to: { name: 'RatesPets', params },
          name: 'Pet Rates',
        }].filter(Boolean),
  }]

  const menu = [{
    icon: mdiSpeedometer,
    name: 'Dashboard',
    to: { name: 'PropertyDashboard', params },
  }, {
    icon: mdiReceiptText,
    to: { name: 'Reservations', params },
    name: 'Reservations',
  }, {
    icon: mdiStorefrontOutline,
    to: { name: 'PointOfSale', params },
    name: 'POS',
  }, {
    icon: mdiContacts,
    to: { name: 'Guests', params },
    name: 'Guests',
  }, {
    icon: mdiCancel,
    to: { name: 'Blackouts', params },
    name: 'Blackout',
  }, {
    icon: mdiCurrencyUsd,
    to: { name: 'Payments', params },
    name: 'Payments',
  }, {
    icon: mdiCart,
    to: { name: 'Purchases', params },
    name: 'Purchases',
  }, {
    icon: mdiBroom,
    to: { name: 'Housekeeping', params },
    name: 'Housekeeping',
    hidden: !isFeatureEnabled(clientShowHousekeepingNav),
  }, {
    icon: mdiChartBar,
    to: { name: 'ReportsIndex', params },
    name: 'Reports',
  }, {
    icon: mdiCogs,
    to: { name: 'Settings', params },
    name: 'Settings',
  },

  ...(newRateVersion ? [] : oldRateNavItems),

  ].filter(Boolean)

  return menu
})

function closeDrawer () {
  drawer.value = false
  key.value = Date.now()
}
</script>
