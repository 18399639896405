<template>
  <MyProfile>
    <CkApp
      :links="links"
      :title="association.name"
    >
      <slot />
    </CkApp>
  </MyProfile>
</template>

<script setup>
import { getCurrentInstance } from 'vue'
import CkApp from './ck-app.vue'
import { useRoute } from '@/composable/router'
import MyProfile from '@/providers/my-profile.vue'

const vm = getCurrentInstance()

const route = useRoute(vm.proxy)

const association = {
  id: route.params.associationId,
  name: 'Association',
}

const links = []
</script>
