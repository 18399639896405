<template>
  <v-breadcrumbs
    class="pa-0 mb-2 d-print-none"
    :items="items"
  />
</template>

<script>
export default {
  props: {
    propertyName: String,
  },
  computed: {
    items () {
      const route = this.$route

      const breadcrumbs = route?.meta?.breadcrumbs || []
      return [{
        text: this.propertyName || 'Property',
        disabled: true,
      },
      ...breadcrumbs.map(([text, name]) => ({
        text,
        to: { name, params: { ...route.params } },
        exact: true,
      })),
      ]
    },
  },
}
</script>
