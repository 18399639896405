<template>
  <v-text-field
    :value="value"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    :type="type"
    :name="myName"
    :rules="myRules"
    :label="label"
    :hint="hint"
    dense
    outlined
    persistent-hint
    v-bind="$attrs"
    autocomplete="off"
    @click:append="clickAppend"
    @click:append-outer="clickAppendOuter"
    @input="i => $emit('input', i)"
  />
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue'
const props = defineProps({
  name: String,
  label: String,
  dense: Boolean,
  icon: String,
  hint: String,
  value: [String, Number],
  autofocus: Boolean,
  required: Boolean,
  type: {
    type: String,
    default: 'text',
  },
  rules: {
    type: Array,
    default: () => [],
  },
})
const vm = getCurrentInstance()
const myRules = computed(() => {
  return props.required
    ? [
        v => v !== '' || 'Field is required',
        ...props.rules,
      ]
    : [...props.rules]
})
const myName = computed(() => {
  return props.name || props.label
    .trim()
    .toLowerCase()
    .replace(/\s/gi, '-')
})
function clickAppend (e) {
  if (vm.proxy.$listeners['click:append']) {
    vm.proxy.$listeners['click:append'](e)
  }
}
function clickAppendOuter (e) {
  if (vm.proxy.$listeners['click:append-outer']) {
    vm.proxy.$listeners['click:append-outer'](e)
  }
}
</script>
