<template>
  <v-banner
    v-if="needRefresh"
    single-line
  >
    New updates available, click RELOAD to install.
    <template #actions>
      <v-btn
        class="mr-2"
        color="info"
        x-small
        @click="updateServiceWorker"
      >
        Reload
      </v-btn>

      <v-btn
        x-small
        @click="closePromptUpdateSW"
      >
        Ignore
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import useRegisterSW from '@/mixins/use-register-sw'

export default {
  name: 'ReloadPrompt',
  mixins: [useRegisterSW],
}
</script>
