import Vue, { computed, reactive } from 'vue'
import Router from 'vue-router'

Vue.use(Router)

if (window && window.history) {
  window.history.scrollRestoration = 'manual'
}

// Enable $router.params to be passed as props to component
const props = true

const routes = [{
  // ******      PUBLIC ROUTES       *******/
  path: '/maintenance',
  component: () => import('./pages/maintenance.vue'),
  meta: { layout: 'SingleLayout' },
}, {
  path: '/version',
  component: () => import('./pages/version.vue'),
  meta: { layout: 'BlankLayout' },
}, {
  path: '/auth',
  component: () => import('./pages/container'),
  children: [{
    name: 'AuthForgot',
    path: 'forgot',
    component: () => import('./pages/auth-forgot.vue'),
    meta: { layout: 'SingleLayout' },
  }, {
    name: 'AuthLogin',
    path: 'login',
    component: () => import('./pages/auth-login.vue'),
    meta: { layout: 'SingleLayout' },
  }, {
    name: 'AuthLogout',
    path: 'logout',
    component: () => import('./pages/auth-logout.vue'),
    meta: { layout: 'SingleLayout' },
  }, {
    name: 'AuthResetCode',
    path: 'reset/:resetCode?',
    component: () => import('./pages/auth-reset-code.vue'),
    meta: { layout: 'SingleLayout' },
    props,
  }],
}, {
  name: 'VerifyEmail',
  path: '/verify/email/:emailId',
  component: () => import('./pages/verify-email.vue'),
  meta: { layout: 'SingleLayout' },
}, {
  // ******      PRIVATE ROUTES       *******/

  name: 'MyProfile',
  path: '/my/profile',
  component: () => import('./pages/my-profile.vue'),
  meta: { layout: 'UserLayout', title: 'My Profile' },
}, {
  name: 'MyProperties',
  path: '/my/properties',
  component: () => import('./pages/my-properties-list.vue'),
  meta: { layout: 'UserLayout', title: 'My Properties' },
}, {
  name: 'MySettings',
  path: '/my/settings',
  component: () => import('./pages/my-settings.vue'),
  meta: { layout: 'UserLayout', title: 'My Settings' },
}, {
  name: 'MyApe',
  path: '/my/ape',
  component: () => import('./pages/my-ape.vue'),
  meta: { layout: 'UserLayout' },
}, {
  path: '/association/:associationId',
  component: () => import('./pages/association-id.vue'),
  children: [{
    path: '',
    redirect: 'dashboard',
  }, {
    name: 'AssociationPreview',
    path: 'preview',
    component: () => import('./pages/preview-association.vue'),
    meta: { layout: 'AssociationLayout' },
  }, {
    name: 'AssociationDashboard',
    path: 'dashboard',
    component: () => import('./pages/association-dashboard.vue'),
    meta: { layout: 'AssociationLayout' },
  }, {
    path: 'property',
    component: () => import('./pages/container'),
    children: [{
      path: ':propertyId',
      component: () => import('./pages/property-id.vue'),
      children: [{
        name: 'AssociationProperty',
        path: '',
        component: () => import('./pages/association-property.vue'),
        meta: { layout: 'AssociationLayout' },
      }],
    }],
  }],
}, {
  path: '/property/:propertyId',
  component: () => import('./pages/property-id'),
  props,
  meta: { layout: 'PropertyLayout' },
  children: [{
    path: '',
    redirect: { name: 'PropertyDashboard' },
  }, {
    name: 'Calendar',
    path: 'calendar',
    component: () => import('./pages/property-calendar.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'Payments',
    path: 'payments',
    component: () => import('./pages/payments.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'Housekeeping',
    path: 'housekeeping',
    component: () => import('./pages/housekeeping.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'Purchases',
    path: 'purchases',
    component: () => import('./pages/purchases.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'PointOfSale',
    path: 'pos',
    component: () => import('./pages/point-of-sale.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'Addons',
    path: 'addon',
    redirect: { name: 'AddonsGrid' },
    component: () => import('./pages/addons.vue'),
    meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']], title: 'Addons' },
    props,
    children: [{
      name: 'AddonsGrid',
      path: 'grid',
      component: () => import('./pages/addons-grid.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']], title: 'Addons' },
      props,
    }, {
      path: 'addon',
      name: 'RatesAddons',
      component: () => import('./pages/rates-addons.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'AddonGroups',
      path: 'addon-group',
      component: () => import('./pages/addon-groups.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }],
  }, {
    name: 'SaleItems',
    path: 'saleitem',
    redirect: { name: 'SaleItemsList' },
    component: () => import('./pages/sale-items.vue'),
    meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
    props,
    children: [{
      name: 'SaleItemsList',
      path: 'list',
      component: () => import('./pages/sale-items-list.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SaleItemGroups',
      path: 'groups',
      component: () => import('./pages/sale-item-groups.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }],
  }, {
    name: 'PropertyDashboard',
    path: 'dashboard',
    component: () => import('./pages/property-dashboard.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'Guests',
    path: 'guest',
    component: () => import('./pages/guests.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'Reservations',
    path: 'reservation',
    redirect: { name: 'ReservationsList' },
    component: () => import('./pages/reservations.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
    children: [{
      name: 'ReservationsCalendar',
      path: 'calendar',
      component: () => import('./pages/reservations-calendar.vue'),
      meta: { layout: 'PropertyLayout' },
      props,
    }, {
      name: 'ReservationsList',
      path: 'list',
      component: () => import('./pages/reservations-list.vue'),
      meta: { layout: 'PropertyLayout' },
      props,
    }, {
      name: 'ReservationsListDepositDue',
      path: 'list-deposit-due',
      component: () => import('./pages/reservations-list-deposit-due.vue'),
      meta: { layout: 'PropertyLayout' },
      props,
    }],
  }, {
    path: 'settings',
    component: () => import('./pages/container'),
    children: [{
      name: 'Settings',
      path: '',
      component: () => import('./pages/settings-index.vue'),
      meta: { layout: 'PropertyLayout' },
      props,
    }, {
      name: 'SettingsGeneral',
      path: 'general',
      component: () => import('./pages/property-settings-general.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'EmailTemplates',
      path: 'email-templates',
      component: () => import('./pages/property-settings-email-templates.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsPeriods',
      path: 'periods',
      component: () => import('./pages/property-periods.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsSeasons',
      path: 'seasons',
      component: () => import('./pages/property-seasons.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsSeason',
      path: 'season/:seasonId',
      component: () => import('./pages/property-season.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings'], ['Seasons', 'SettingsSeasons']] },
      props,
    }, {
      name: 'SettingsActions',
      path: 'actions',
      component: () => import('./pages/property-actions.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsTaxes',
      path: 'taxes',
      component: () => import('./pages/property-taxes.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsUsers',
      path: 'users',
      component: () => import('./pages/property-users.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsTax',
      path: 'tax/:taxId',
      component: () => import('./pages/property-tax.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings'], ['Taxes', 'SettingsTaxes']] },
      props,
    }, {
      name: 'SettingsAmenities',
      path: 'amenities',
      component: () => import('./pages/property-amenities.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsImages',
      path: 'images',
      component: () => import('./pages/property-images.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsLogo',
      path: 'logo',
      component: () => import('./pages/property-logo.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsAgeGroups',
      path: 'age-groups',
      component: () => import('./pages/property-age-groups.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsContact',
      path: 'contact',
      component: () => import('./pages/property-contact.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsDeposit',
      path: 'deposit',
      component: () => import('./pages/property-settings-deposit.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsRateAdjustments',
      path: 'rate-adjustment',
      component: () => import('./pages/property-rate-adjustments.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
    }, {
      name: 'SettingsRatePlans',
      path: 'rate-plan',
      redirect: { name: 'RatePlansList' },
      component: () => import('./pages/property-rate-plans.vue'),
      props,
      children: [{
        name: 'RatePlansList',
        path: 'list',
        component: () => import('./pages/property-rate-plans-list.vue'),
        meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
        props,
      }, {
        name: 'RatePlansAssignments',
        path: 'assignments',
        component: () => import('./pages/rate-plans-units.vue'),
        meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
        props,
      }, {
        name: 'SeasonUnitRateGrid',
        path: 'simple-rate-grid',
        component: () => import('./pages/season-unit-rate-grid.vue'),
        meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
        props,
      }],
    }, {
      name: 'SettingsRatePlan',
      path: 'rate-plan/:ratePlanId',
      component: () => import('./pages/property-rate-plan.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings'], ['Rate Plans', 'SettingsRatePlans']] },
      props,
    }, {
      name: 'SettingsStayRules',
      path: 'stay-rule',
      redirect: { name: 'StayRulesList' },
      component: () => import('./pages/property-stay-rules.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
      props,
      children: [{
        name: 'StayRulesList',
        path: 'list',
        component: () => import('./pages/property-stay-rules-list.vue'),
        meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
        props,
      }, {
        name: 'StayRulesAssignments',
        path: 'assignments',
        component: () => import('./pages/stay-rules-units.vue'),
        meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
        props,
      }],
    }, {
      name: 'SettingsStayRule',
      path: 'stay-rule/:stayRuleId',
      component: () => import('./pages/property-stay-rule.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings'], ['Stay Rules', 'SettingsStayRules']] },
      props,
    }],
  }, {
    name: 'Units',
    path: 'unit',
    component: () => import('./pages/units.vue'),
    meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
    props,
  }, {
    name: 'Blackouts',
    path: 'blackouts',
    component: () => import('./pages/blackouts.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
  }, {
    name: 'UnitBlackout',
    path: 'blackouts/unit/:blackoutId',
    component: () => import('./pages/blackout-unit.vue'),
    meta: { layout: 'PropertyLayout', breadcrumbs: [['Blackouts', 'Blackouts']] },
    props,
  }, {
    name: 'AddonBlackout',
    path: 'blackouts/addon/:blackoutId',
    component: () => import('./pages/blackout-addon.vue'),
    meta: { layout: 'PropertyLayout', breadcrumbs: [['Blackouts', 'Blackouts']] },
    props,
  }, {
    name: 'Addon',
    path: 'addon/:addonId',
    redirect: { name: 'AddonGeneral' },
    component: () => import('./pages/addon.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
    children: [{
      name: 'AddonGeneral',
      path: 'setting',
      component: () => import('./pages/addon-general.vue'),
      meta: {
        layout: 'PropertyLayout',
        breadcrumbs: [
          ['Settings', 'Settings'],
          ['Addons', 'Addons'],
        ],
      },
      props,
    }, {
      name: 'AddonImages',
      path: 'images',
      component: () => import('./pages/addon-images.vue'),
      meta: {
        layout: 'PropertyLayout',
        breadcrumbs: [
          ['Settings', 'Settings'],
          ['Addons', 'Addons'],
        ],
      },
      props,
    }, {
      name: 'AddonRates',
      path: 'rates',
      component: () => import('./pages/addon-rates.vue'),
      props,
      meta: {
        layout: 'PropertyLayout',
        breadcrumbs: [
          ['Settings', 'Settings'],
          ['Addons', 'Addons'],
        ],
      },
    }],
  }, {
    path: 'reservation/:reservationId',
    component: () => import('./pages/reservation-id.vue'),
    meta: { layout: 'PropertyLayout', breadcrumbs: [['Reservations', 'Reservations']] },
    props,
    children: [{
      name: 'Reservation',
      path: '',
      component: () => import('./pages/reservation.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reservations', 'Reservations']] },
    }],
  }, {
    path: 'guest/:guestId',
    component: () => import('./pages/guest-id.vue'),
    props,
    children: [{
      name: 'Guest',
      path: '',
      component: () => import('./pages/guest-info.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Guests', 'Guests']] },
      props,
    }],
  }, {
    path: 'saleitem/:saleItemId',
    component: () => import('./pages/sale-item.vue'),
    children: [{
      name: 'SaleItemGeneral',
      path: '',
      component: () => import('./pages/sale-item-general.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings'], ['Sale Items', 'SaleItems']] },
      props,
    }],
    props,
  }, {
    name: 'Unit',
    path: 'unit/:unitId',
    redirect: { name: 'UnitGeneral' },
    component: () => import('./pages/unit.vue'),
    meta: { layout: 'PropertyLayout' },
    props,
    children: [{
      name: 'UnitGeneral',
      path: 'settings',
      component: () => import('./pages/unit-general.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Units', 'Units']] },
      props,
    }, {
      name: 'UnitImages',
      path: 'images',
      component: () => import('./pages/unit-images.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Units', 'Units']] },
      props,
    }, {
      name: 'UnitPetRates',
      path: 'pet-rates',
      component: () => import('./pages/unit-pet-rates.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Units', 'Units']] },
      props,
    }, {
      name: 'UnitRates',
      path: 'rates',
      component: () => import('./pages/unit-rates.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Units', 'Units']] },
      props,
    }, {
      name: 'UnitRooms',
      path: 'rooms',
      component: () => import('./pages/unit-rooms.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Units', 'Units']] },
      props,
    }],
  }, {
    path: 'preview',
    name: 'PropertyPreview',
    component: () => import('./pages/preview.vue'),
    meta: { layout: 'PropertyLayout', breadcrumbs: [['Settings', 'Settings']] },
    props,
  }, {
    path: 'rates',
    component: () => import('./pages/container.vue'),
    meta: { layout: 'PropertyLayout' },
    children: [{
      path: 'unit',
      name: 'RatesUnits',
      component: () => import('./pages/rates-units.vue'),
      meta: { layout: 'PropertyLayout' },
      props,
    }, {
      path: 'pet',
      name: 'RatesPets',
      component: () => import('./pages/rates-pets.vue'),
      meta: { layout: 'PropertyLayout' },
      props,
    }],
  }, {
    path: 'reports',
    component: () => import('./pages/container.vue'),
    children: [{
      name: 'ReportsIndex',
      path: '',
      component: () => import('./pages/reports-index.vue'),
      meta: { layout: 'PropertyLayout' },
      props,
    }, {
      path: 'taxes',
      name: 'ReportsTaxes',
      component: () => import('./pages/reports-taxes.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reports', 'ReportsIndex']] },
      props,
    }, {
      path: 'revenue',
      name: 'ReportsRevenue',
      component: () => import('./pages/reports-revenue.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reports', 'ReportsIndex']] },
      props,
    }, {
      path: 'revenue-unit',
      name: 'ReportsUnitRevenue',
      component: () => import('./pages/reports-unit-revenue.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reports', 'ReportsIndex']] },
      props,
    }, {
      path: 'revenue-addon',
      name: 'ReportsAddonRevenue',
      component: () => import('./pages/reports-addon-revenue.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reports', 'ReportsIndex']] },
      props,
    }, {
      path: 'revenue-sale-item',
      name: 'ReportsSaleItemRevenue',
      component: () => import('./pages/reports-sale-item-revenue.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reports', 'ReportsIndex']] },
      props,
    }, {
      path: 'payments',
      name: 'ReportsPayments',
      component: () => import('./pages/reports-payments.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reports', 'ReportsIndex']] },
      props,
    }, {
      path: 'stripe',
      name: 'ReportsStripe',
      component: () => import('./pages/reports-stripe.vue'),
      meta: { layout: 'PropertyLayout', breadcrumbs: [['Reports', 'ReportsIndex']] },
      props,
    }],
  }],

  // ******      CATCH ROUTES       *******/
}, {
  path: '/colors',
  name: 'Colors',
  component: () => import('./pages/colors.vue'),
  meta: { layout: 'BlankLayout' },
}, {
  path: '/',
  name: 'Index',
  component: () => import('./pages/index.vue'),
  meta: { layout: 'BlankLayout' },
}, {
  path: '*',
  name: 'notfound',
  component: () => import('./pages/404.vue'),
  meta: { layout: 'SingleLayout' },
}, {
  path: '/test',
  name: 'test',
  component: () => import('./components/test.vue'),
  meta: { layoutx: 'SingleLayout' },
}]

const maintenanceRoutes = [{
  path: '*',
  name: 'notfound',
  component: () => import('./pages/maintenance.vue'),
  meta: { layout: 'SingleLayout' },
}]

function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) return savedPosition
  if (to.hash) return { selector: to.hash }
  return { x: 0, y: 0 }
}

/**
* @returns Router
*/
export function useRouter (proxy) {
  console.warn('use @/composable/router instead')
  return proxy.$router
}

/**
* @returns Route
*/
export function useRoute (proxy) {
  console.warn('use @/composable/router instead')
  const currentRoute = computed(() => proxy.$route)

  const protoRoute = Object.keys(currentRoute.value).reduce(
    (acc, key) => {
      acc[key] = computed(() => currentRoute.value[key])
      return acc
    },
    {}
  )

  return reactive(protoRoute)
}

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  scrollBehavior,
  routes: isMaintenanceMode() ? maintenanceRoutes : routes,

  fallback: false,
})

function isMaintenanceMode () {
  // Check if CK_CONFIG is defined and is an object
  if (typeof CK_CONFIG !== 'object' || CK_CONFIG === null) {
    return false
  }

  // Check if VUE_APP_SHOW_MAINTENANCE is defined
  if (!Object.prototype.hasOwnProperty.call(CK_CONFIG, 'VUE_APP_SHOW_MAINTENANCE')) {
    return false
  }

  // Get the value of VUE_APP_SHOW_MAINTENANCE
  const value = CK_CONFIG.VUE_APP_SHOW_MAINTENANCE

  // Check if the value is a string
  if (typeof value !== 'string') {
    return false
  }

  // Normalize the value
  const normalizedValue = value.toLowerCase().trim()

  // Check if the normalized value is 'true'
  if (normalizedValue.charAt(0) === 't') {
    return true
  } else {
    return false
  }
}

// router.afterEach: to => {
//   nextTick(() => {
//     posthog.capture("$pageview", {
//       $current_url: to.fullPath
//     });
//   });
// }

// export let activedRoutes = []

// router.beforeEach((to, from, next) => {
//   activedRoutes = []
//   activedRoutes = [ ...to.matched ]
//   console.log(activedRoutes)
//   next()
// })

export default router
