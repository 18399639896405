import { computed } from 'vue'
import { useBreakpoints } from '@vueuse/core'

// The Vuetify breakpoints from the package are wrong
const breakpointsVuetify = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1264,
  xl: 1904,
}

export const breakpoints = useBreakpoints(breakpointsVuetify)

export const xs = breakpoints.xs
export const sm = breakpoints.sm
export const md = breakpoints.md
export const lg = breakpoints.lg
export const xl = breakpoints.xl

export const xsOnly = breakpoints.smaller('sm')

export const smAndUp = breakpoints.greater('xs')
export const smAndDown = breakpoints.smaller('md')

export const mdAndUp = breakpoints.greater('md')
export const lgAndUp = breakpoints.greater('md')

export const name = computed(() => {
  const points = breakpoints.current().value
  return points[points.length - 1]
})

// greater(k: K): Ref<boolean>;
// greaterOrEqual: (k: K) => Ref<boolean>;
// smaller(k: K): Ref<boolean>;
// smallerOrEqual(k: K): Ref<boolean>;
// between(a: K, b: K): Ref<boolean>;
