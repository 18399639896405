import { useQuery, useMutation } from '@vue/apollo-composable'
import MyProfileGQL from 'GQL/my-profile.gql'
import UserAccountGQL from 'GQL/user-account.gql'
import ApeGQL from '@/gql/ape.gql'

export function getMyProfile () {
  return useQuery(MyProfileGQL, {})
}

export function updateUserAccount () {
  return useMutation(UserAccountGQL)
}

export function impersonateUser () {
  return useMutation(ApeGQL)
}

export function isAdmin (myProfile) {
  return Boolean(myProfile?.userAccount?.isAdmin)
}
