<script setup>
import { computed, provide } from 'vue'
import { getMyProfile, isAdmin as isAdminCheck } from '@/services/my-profile'

const { result } = getMyProfile()
const myProfile = computed(() => {
  return result.value?.myProfile
})
const isAdmin = computed(() => {
  return isAdminCheck(result.value?.myProfile)
})
provide('myProfile', myProfile)
provide('isAdmin', isAdmin)
</script>

<script>
// Render doesn't work script setup
export default {
  render () {
    // Renderless component, otherwise it messes with the layout of the page
    // https://adamwathan.me/renderless-components-in-vuejs/
    return this.$scopedSlots.default({})
  },
}
</script>
