/**
 * Had to upgrade the persisted query because apollo-link-persisted-queries was
 * trying to import a `hash.js` library that threw an error in vite about
 * 'required is not defined'
 *
 * Importing the top @apollo/client throws an error that `react` is not
 * defined.
 *
 * Might need to look for another client GQL solution that isn't hard-coded to
 * react that Apollo has become.
 */
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
// import introspectionQueryResultData from './gql/fragment-types.json'
// import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { onError } from '@apollo/client/link/error'
import { EventBus } from '@/utils/bus'
import { sha256 } from 'crypto-hash'

const PATH = CK_CONFIG.VUE_APP_API_PATH || ''

const KEY = CK_CONFIG.VUE_APP_APOLLO_TOKEN || 'apollo-token'

const httpLink = createHttpLink({
  uri: `${PATH}/graphql`,
})

const persisted = createPersistedQueryLink({
  useGETForHashedQueries: true,
  sha256,
})

const defaultOptions = {
  // $loadingKey: 'loading',
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
}

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// })

const redirectErrorCodes = [
  'AuthenticationError',
  'Forbidden',
  'FORBIDDEN',
  'UNAUTHENTICATED',
]

const loginRegExp = /\/auth\/login/i

function isAuthCode (code, extensions) {
  if (code && redirectErrorCodes.includes(code)) return true
  if (extensions && extensions.code && redirectErrorCodes.includes(extensions.code)) return true
  return false
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const redirectLocation = `/auth/login?redirectTo=${location.pathname}`
  if (networkError && networkError.statusCode === 401 && !loginRegExp.test(location.pathname)) {
    location = redirectLocation
    return
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, code, extensions }) => {
      if (isAuthCode(code, extensions) && !loginRegExp.test(location.pathname)) {
        location = redirectLocation
      } else {
        EventBus.emit('show-snack', { title: 'Error', message })
      }
    })
  }
})

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
    },
  }
})

export const cache = new InMemoryCache({
  // fragmentMatcher,
  possibleTypes: {
    Payment: ['PropertyOrderPayment', 'PersonPayment'],
  },
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    persisted,
    httpLink,
  ].filter(Boolean)),
  cache,
  defaultOptions,
})

// Manually call this when user log in
export async function onLogin (apolloClient) {
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(KEY)
  }
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}
