import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useProperty = defineStore('property', () => {
  const currentProperty = ref()

  const newRateVersion = computed(() => {
    if (!currentProperty.value) return false
    return currentProperty.value?.rateVersion === 2
  })

  function selectCurrentProperty (val) {
    currentProperty.value = val
  }

  return {
    currentProperty,
    selectCurrentProperty,
    newRateVersion,
  }
})
