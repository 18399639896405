<template>
  <v-menu
    open-on-hover
    bottom
    left
    offset-y
  >
    <template #activator="{ on }">
      <v-btn
        icon
        dark
        v-on="on"
      >
        <v-icon>{{ mdiAccountCircle }}</v-icon>
      </v-btn>
    </template>

    <v-list
      dense
    >
      <v-list-item
        exact
        to="/my/profile"
      >
        <v-list-item-action>
          <v-icon>{{ mdiAccountCircle }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>My Profile</v-list-item-title>
      </v-list-item>

      <div v-if="favoritePropertyIds.length">
        <v-list-item
          v-for="propertyId in favoritePropertyIds"
          :key="propertyId"
          @click="selectPropertyById(propertyId)"
        >
          <v-list-item-title>
            <v-icon
              x-small
              left
            >
              {{ mdiStar }}
            </v-icon>{{ getPropertyById(propertyId).name }}
          </v-list-item-title>
        </v-list-item>
      </div>

      <v-divider />

      <template v-if="properties && properties.length">
        <div v-if="properties.length < 4">
          <v-subheader>My Properties</v-subheader>
          <v-list-item
            v-for="(property, index) in properties"
            :key="index"
            @click="selectProperty(property)"
          >
            <v-list-item-title>{{ property.name }}</v-list-item-title>
          </v-list-item>
        </div>
        <v-list-item
          v-else
          to="/my/properties"
        >
          <v-list-item-action>
            <v-icon>{{ mdiDomain }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>My Properties</v-list-item-title>
        </v-list-item>
        <v-divider />
      </template>

      <template v-if="associations && associations.length">
        <v-subheader>{{ associationMenuLabel }}</v-subheader>
        <v-row wrap>
          <v-col cols="12">
            <v-list-item
              v-for="(association, index) in associations"
              :key="index"
              @click="selectAssociation(association)"
            >
              <v-list-item-title>{{ association.name }}</v-list-item-title>
            </v-list-item>
          </v-col>
        </v-row>

        <v-divider />
      </template>

      <v-list-item :href="supportLink">
        <v-list-item-action>
          <v-icon>{{ mdiHelpCircle }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>Email Support</v-list-item-title>
      </v-list-item>

      <v-list-item :href="`${WWW_URL}/docs/`">
        <v-list-item-action>
          <v-icon>{{ mdiBookOpenVariant }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>Documentation</v-list-item-title>
      </v-list-item>

      <v-list-item @click="onLogout">
        <v-list-item-action>
          <v-icon>{{ mdiLogout }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>Sign Out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue'
import {
  mdiAccountCircle,
  mdiBookOpenVariant,
  mdiDomain,
  mdiHelpCircle,
  mdiLogout,
  mdiStar,
} from '@mdi/js'
import { useProperty } from '@/store/property'
import { useRoute } from '@/composable/router'

const PROPERTY_URL_REGEX = /^\/property\/*/i
const propertyStore = useProperty()
const WWW_URL = CK_CONFIG.VUE_APP_WWW_URL
const SUPPORT_EMAIL = CK_CONFIG.VUE_APP_HELP_EMAIL
const vm = getCurrentInstance()
const route = useRoute(vm.proxy)

const isPropertyUrl = computed(() => {
  return PROPERTY_URL_REGEX.test(route.fullPath)
})
const supportLink = computed(() => {
  const subject = isPropertyUrl.value && propertyStore.currentProperty ? `?subject=${propertyStore.currentProperty.name} - ` : ''
  return `mailto:${SUPPORT_EMAIL}${subject}`
})

const props = defineProps({
  properties: Array,
  associations: Array,
  favoritePropertyIds: Array,
  id: String,
})
const emits = defineEmits(['select-property', 'select-association', 'logout'])

const associationMenuLabel = computed(() => {
  return props.associations.length === 1
    ? 'Association'
    : 'Associations'
})

function getPropertyById (id) {
  return props.properties.find(prop => prop.id === id) || {}
}

function selectPropertyById (id) {
  const property = getPropertyById(id)
  if (!property) return
  selectProperty(property)
}

function selectProperty (property) {
  emits('select-property', property)
}

function selectAssociation (association) {
  emits('select-association', association)
}

function onLogout () {
  emits('logout')
}
</script>
